const Uppy = require('@uppy/core');
const Dashboard = require('@uppy/dashboard');
const AwsS3 = require('@uppy/aws-s3');
const DragDrop = require('@uppy/drag-drop');

$(function () {
  let isFileAdded = false;
  let isDocumentTypeSelected = false;

  const uppy = Uppy({ autoProceed: false });

  uppy.use(AwsS3, {
    getUploadParameters(file) {
      return fetch(`/uploads/presign?filename=${file.name}`).then((response) =>
        response.json(),
      );
    },
  });

  if ($('.dropzone').length > 0) {
    uppy.use(Dashboard, {
      target: '.dropzone',
      inline: true,
      showProgressDetails: true,
      hideUploadButton: true,
      proudlyDisplayPoweredByUppy: false,
      locale: {
        strings: {
          dropPaste: 'Datei(en) hineinziehen oder %{browse}',
          browse: 'hier klicken',
        },
      },
    });
  }

  function checkUploadButton() {
    if (isFileAdded && isDocumentTypeSelected) {
      $('#upload-submit-button').prop('disabled', false);

      $('#upload-tooltip').tooltip('disable');
    } else {
      $('#upload-submit-button').prop('disabled', true);

      $('#upload-tooltip').tooltip('enable');
    }
  }

  uppy.on('file-added', function (file) {
    isFileAdded = true;
    checkUploadButton();
  });

  uppy.on('file-removed', function (file) {
    const files = uppy.getFiles();
    isFileAdded = files.length > 0;
    checkUploadButton();
  });

  $('#attachment_document_type').on('change', function () {
    isDocumentTypeSelected = $(this).val() !== '';
    checkUploadButton();
  });

  uppy.on('upload-success', function (file, data) {
    const $form = $('form.new_attachment');

    const $fileInput = $('<input>').attr({
      type: 'hidden',
      name: 'attachment[files][]',
    });

    const uploadedFileData = JSON.stringify({
      id: file.meta['key'].match(/^cache\/(.+)/)[1],
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      },
    });

    $fileInput.val(uploadedFileData);
    $fileInput.appendTo($form);
  });

  $('#upload-submit-button').on('click', function (e) {
    e.preventDefault();

    const selectedDocumentType = $('#attachment_document_type').val();

    const $form = $('form.new_attachment');
    const $documentTypeInput = $('<input>').attr({
      type: 'hidden',
      name: 'attachment[document_type]',
      value: selectedDocumentType,
    });

    $documentTypeInput.appendTo($form);

    uppy.upload().then((result) => {
      if (result.successful.length > 0) {
        $form.submit();
      }
    });
  });
});
