document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#inboundArrivals-table'),
    columns: [
      { data: 'number' },
      { data: 'status' },
      { data: 'trucker' },
      { data: 'driver_present_at' },
      { data: 'hauler' },
      { data: 'license_plate' },
      { data: 'phone' },
      { data: 'airport' },
      { data: 'depot_name' },
      { data: "truck_sealed" },
    ],
    order: [[0, 'desc'], [4, 'desc']],
  });
});
