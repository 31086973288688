document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#inboundMasterLoads-table'),
    columns: [
      { data: 'status' },
      { data: 'created_via' },
      { data: 'number', className: 'text-right mono' },
      { data: 'creation_time' },
      { data: 'pieces', className: 'text-right mono' },
      { data: 'weight', className: 'text-right mono' },
      { data: 'tours_number' },
      { data: 'updated_at' },
    ],
    order: [[1, 'asc']],
    paging: false,
  });
});
