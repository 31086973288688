$ ->
  window.toggleWrapper = () ->
    $wrapper = $('#wrapper')

    if $wrapper.hasClass('toggled')
      $wrapper.removeClass('toggled')
      sessionStorage.setItem('showFilterbar', 'false')
    else
      $wrapper.addClass('toggled')
      sessionStorage.setItem('showFilterbar', 'true')

  window.showHideFilterbar = () ->
    showFilterbar = sessionStorage.getItem('showFilterbar')
    $wrapper = $('#wrapper')
    if $wrapper.data('filterbarContent')
      filterbarContent = 'true'
    else
      filterbarContent = 'false'

    unless showFilterbar
      sessionStorage.setItem('showFilterbar', 'true')
      showFilterbar = sessionStorage.getItem('showFilterbar')

    if(showFilterbar == 'true' and filterbarContent == 'true')
      $wrapper.addClass('toggled')
    else
      $wrapper.removeClass('toggled')

  window.showHideFilterbar()

  $.fn.dataTable.ext.errMode = 'none'

  $(document).on 'click', '.advanced_filters', ->
    $panel = $(this).find('[aria-expanded]')
    panelState = if ($panel.attr('aria-expanded') == 'true') then 'show' else 'hide'

    localStorage.setItem($panel.data('storagekey'), panelState)

  # See: https://datatables.net/reference/event/error
  # for documentation about this DataTable event
  $(document).on 'error.dt', 'table.dataTable', (event, settings, techNote, message) ->
    # show error modal
    dataConfirmModal.confirm
      title: 'Fehler'
      text: 'Bei der Suche ist ein Fehler aufgetreten – bitte überprüfen Sie Ihre Eingabe.<br><br><code>Fehlercode: ' + techNote + '</code>'
      cancelClass: 'hidden'
      commitClass: 'btn-primary'
    $('.browser-notice').fadeIn()

    # stop spinning cursor
    $('html:not(.no-loading-indicator)').removeClass('loading-pre')
    # stop spinner
    $('.dataTables_wrapper').removeClass('loading')

    error_log = {
      error: message,
      # Note that airbrake.notify stringifies the data you send it
      # and some settings contain circular references and can't be
      # stringified. Sending only a few specific settings.
      context: {
        'dataTableErrorNumber': techNote
      },
    }
    if ((process.env.RAILS_ENV != 'development') && (process.env.RAILS_ENV != 'test'))
      airbrake.notify(error_log)
    else
      console.warn(error_log)

    # See: https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror
    # When the function returns true, this prevents the firing of the default event handler
    return true
    # This is neccessary because if the event is handled by window.onerror
    # Then Airbrake will send the event object to airbrake creating the famous Error: [object Object]

  $('div.advanced_filters #advanced-filter-message-box .close').click (e) ->
    e.preventDefault()
    e.stopImmediatePropagation()
    $('#advanced-filter-message-box').hide()

  $('div.advanced_filters .panel-title a').click (e) ->
    inputs = $('div.advanced_filters').find(':input')
    values = $.map(inputs, (e) -> return $(e).val())

    # if there are only empty strings in them values - Schwingschleifer - that means no filter is used.
    empty = values.every((e) -> e == "")

    unless (empty)
      active_filters = []

      for i, input of inputs.toArray()
        unless $(input).val() == ""
          label_html = $(input).prevUntil('div','label').html()
          input_val = $(input).val()

          active_filters.push('' + label_html)

      e.preventDefault()
      e.stopImmediatePropagation()
      $('#advanced-filter-message-box p').html('Die zusätzlichen Filter können nicht ausgeblendet werden.' + '</br>' + ' Es sind noch Filter aktiv:' + '</br><b>' + active_filters.join('</b></br><b>') + '</b>')
      $('#advanced-filter-message-box').show()

  increment_counter = (counterId) ->
    new_counter = "" + (parseInt($("##{counterId}").html(), 10) + 1)
    $("##{counterId}").html(new_counter)

  decrement_counter = (counterId) ->
    new_counter = "" + (parseInt($("##{counterId}").html(), 10) - 1)
    $("##{counterId}").html(new_counter)

  addToPiecesCount = (piecesCounterId, piecesCount) ->
    if $("##{piecesCounterId}").length
      piecesCount = "" + (parseInt($("##{piecesCounterId}").html(), 10) + piecesCount)
      $("##{piecesCounterId}").html(piecesCount)
      compareToExpectedPiecesCount(parseInt(piecesCount))

  subtractFromPiecesCount = (piecesCounterId, piecesCount) ->
    if $("##{piecesCounterId}").length
      piecesCount = "" + (parseInt($("##{piecesCounterId}").html(), 10) - piecesCount)
      $("##{piecesCounterId}").html(piecesCount)
      compareToExpectedPiecesCount(parseInt(piecesCount))

  compareToExpectedPiecesCount = (piecesCount) ->
    expectedPiecesCount = parseInt($('#expected_pieces_count').text())

    $expectedPiecesCountComparison = $('#expected-pieces-count-comparison')
    $linkShipmentsButton = $('#link_shipments_button')

    if piecesCount < expectedPiecesCount
      $expectedPiecesCountComparison.text('Nicht genügend Packstücke')
      $expectedPiecesCountComparison.removeClass('alert-warning')
      $expectedPiecesCountComparison.addClass('alert alert-info')
      $linkShipmentsButton.prop('disabled', false)
    if piecesCount == expectedPiecesCount || piecesCount == 0
      $expectedPiecesCountComparison.removeClass('alert alert-info alert-warning')
      $expectedPiecesCountComparison.text('')
      $linkShipmentsButton.prop('disabled', false)
    if piecesCount > expectedPiecesCount
      $expectedPiecesCountComparison.text('Zu viele Packstücke, Verknüpfen nicht möglich')
      $expectedPiecesCountComparison.removeClass('alert-info')
      $expectedPiecesCountComparison.addClass('alert alert-warning')
      $linkShipmentsButton.prop('disabled', true)

  push_row = (t, row, target) ->
    window.markedRowIds.push t.id
    row.fadeOut 'fast', ->
      row.appendTo(target).fadeIn('fast')

  pop_row = (t, row, target, splice_index) ->
    row.fadeOut 'fast', ->
    row.prependTo(target).fadeIn('fast')
    window.markedRowIds.splice(splice_index, 1) if splice_index != -1

  window.markAndUnmarkRowItems = (tableID) ->
    unmarkedTableId = "#{tableID}-table"
    markedTableId = "#{tableID}-marked-items"
    counterId = "#{tableID}-marked-items-count"
    piecesCounterId = "#{tableID}-marked-pieces-count"

    $("##{unmarkedTableId} > tbody").on 'click', 'tr', ->
      $this = $(this)
      $markedItemsTbody = $("##{markedTableId} > tbody")
      piecesCount = parseInt($this.children(".pieces").first().text())

      unless this.id in window.markedRowIds
        push_row(this, $this, $markedItemsTbody)
        increment_counter(counterId)
        addToPiecesCount(piecesCounterId, piecesCount)

    $("##{markedTableId} > tbody").on 'click', 'tr', ->
      $this = $(this)
      $unmarkedItemsTbody = $("##{unmarkedTableId} > tbody")
      piecesCount = parseInt($this.children(".pieces").first().text())
      index = window.markedRowIds.indexOf(this.id)

      if this.id in window.markedRowIds
        pop_row(this, $this, $unmarkedItemsTbody, index)
        decrement_counter(counterId)
        subtractFromPiecesCount(piecesCounterId, piecesCount)

  $('a.mark_all_button').click (e) ->
    e.preventDefault()
    e.stopImmediatePropagation()

    table = $('.dataTable')
    tableID = table.attr('id').split('-')[0]
    counterId = "#{tableID}-marked-items-count"
    piecesCounterId = "#{tableID}-marked-pieces-count"
    unmarkedTableId = "#{tableID}-table"
    markedTableId = "#{tableID}-marked-items"
    $markedItemsTbody = $("##{markedTableId} > tbody")

    trs = $("##{unmarkedTableId} > tbody").find('tr')

    trs.each(
      (i, tr) ->
        push_row(tr, $(tr), $markedItemsTbody)
        increment_counter(counterId)
        piecesCount = parseInt($(tr).children(".pieces").first().text())
        addToPiecesCount(piecesCounterId, piecesCount)
    )

  $('a.unmark_all_button').click (e) ->
    e.preventDefault()
    e.stopImmediatePropagation()

    table = $('.dataTable')
    tableID = table.attr('id').split('-')[0]
    counterId = "#{tableID}-marked-items-count"
    piecesCounterId = "#{tableID}-marked-pieces-count"
    unmarkedTableId = "#{tableID}-table"
    markedTableId = "#{tableID}-marked-items"
    $unmarkedItemsTbody = $("##{unmarkedTableId} > tbody")

    trs = $("##{markedTableId} > tbody").find('tr')

    trs.each(
      (i, tr) ->
        index = window.markedRowIds.indexOf(tr.id)
        pop_row(tr, $(tr), $unmarkedItemsTbody, index)
        decrement_counter(counterId)
        piecesCount = parseInt($(tr).children(".pieces").first().text())
        subtractFromPiecesCount(piecesCounterId, piecesCount)
    )

  window.markedRowIds = []
