document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#stocktakings-table'),
    columns: [
      { data: 'id', orderable: false },
      { data: 'status' },
      { data: 'number' },
      { data: 'depot_name' },
      { data: 'stocktaking_type' },
      { data: 'created_at' },
      { data: 'user_name' },
      { data: 'shipment_findings_count', className: 'text-center' },
      { data: 'unidentified_findings_count', className: 'text-center' },
    ],
    order: [
      [1, 'desc'],
      [5, 'desc'],
    ],
  });
});
