const documentReady = (callback) => {
  if (document.readyState !== 'loading') callback();
  else document.addEventListener('DOMContentLoaded', callback);
};

const logOut = () => {
  const logOutButton = document.getElementById('logout-button');
  if (logOutButton) {
    logOutButton.click();
  }
};

documentReady(() => {
  if (window.location.pathname === '/users/sign_in') return;

  const { idleTimeout } = document.querySelector('body').dataset;
  if (!idleTimeout) return;

  let idleStart = Date.now();

  const resetTimeout = () => {
    idleStart = Date.now();
  };

  document.onkeydown = resetTimeout;
  document.onmousemove = resetTimeout;
  document.onclick = resetTimeout;
  document.onscroll = resetTimeout;

  const checkIdleTime = () => {
    const timeLeft = idleStart + idleTimeout * 1000 - Date.now();
    if (timeLeft <= 0) {
      logOut();
      return;
    }

    const countdown = document.getElementById('logout-countdown');
    if (countdown) {
      const minutes = `${Math.trunc(timeLeft / 60000)}`.padStart(2, '0');
      const seconds = `${Math.trunc(timeLeft / 1000) % 60}`.padStart(2, '0');
      countdown.innerText = `${minutes}:${seconds}`;
    }

    window.setTimeout(checkIdleTime, 1000);
  };

  checkIdleTime();
});
