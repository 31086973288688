document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTable({
    $element: $('#nestedMeasurements-table'),
    columns: [
      { data: 'length' },
      { data: 'width', className: 'mono' },
      { data: 'height', className: 'mono' },
      { data: 'weight', className: 'mono' },
      { data: 'input_method' },
      { data: 'created_at', className: 'text-right' },
      { data: 'edit', orderable: false, className: 'text-right' },
      { data: 'destroy', orderable: false, className: 'text-right' },
    ],
    pageLength: 25,
    order: [[5, 'desc']],
  });
});
