document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#inboundULDs-table'),
    columns: [
      { data: 'status' },
      { data: 'number', className: 'mono' },
      { data: 'client_name' },
      { data: 'created_at', className: 'text-right' },
    ],
    order: [[3, 'desc']],
  });
});
