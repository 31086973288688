document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#inboundPickups-table'),
  });

  $(document).on('click', 'tr.link_pickup', function (e) {
    e.preventDefault();

    const $this = $(this);
    const $form = $this.closest('form');

    $form.attr('action', $this.data('href')).submit();
  });
});
