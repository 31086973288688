$ ->
  initPopovers = ->
    $("a[rel~=popover], .has-popover").popover(html : true)
  initPopovers()

  initTooltips = ->
    $("a[rel~=tooltip], .has-tooltip").tooltip()
  initTooltips()

  $(document).ajaxComplete ->
    initPopovers()
    initTooltips()
