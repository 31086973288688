document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#nestedSSCCs-table'),
    columns: [
      { data: 'sscc', className: 'mono' },
      { data: 'created_at' },
    ],
    order: [[0, 'asc']],
    paging: false,
  });
});
