$(function() {
  const sane_input_types = "input:not(.no-special-input-handling), select:not(.no-special-input-handling), button:not(.no-special-input-handling), textarea:not(.no-special-input-handling)";
  $("body").on("keydown", sane_input_types, function(e) {
    const enter_key = 13;
    const tab_key = 9;

    if ([tab_key, enter_key].includes(e.keyCode)) {
      let input_array;
      const self = $(this);
      const form = self.parents('form:eq(0)');

      // some controls should just press enter when pressing enter
      if (e.keyCode === enter_key) {
        if (e.ctrlKey) {
          form.find('button:first').click().attr('disabled', true);
          return false;
        }

        switch (self.prop('type')) {
          case 'submit':
            return true;
          case 'textarea':
            var value = $(this).val();
            if (value.substr(value.lastIndexOf("\n") + 1) !== '') {
              return;
            }
            break;
        }
      }

      // Sort by tab indexes if they exist
      const tab_index = parseInt(self.attr('tabindex'));
      if (tab_index) {
        input_array = form.find("[tabindex]").filter(':visible:enabled:not([readonly])').sort((a, b) => parseInt($(a).attr('tabindex')) - parseInt($(b).attr('tabindex')));
      } else {
        input_array = form.find(sane_input_types).filter(':visible:enabled:not([readonly])');
      }

      // reverse the direction if using shift
      const move_direction = e.shiftKey ? -1 : 1;
      let new_index = input_array.index(this) + move_direction;

      // wrap around the controls
      if (new_index === input_array.length) {
        new_index = 0;
      } else if (new_index === -1) {
        new_index = input_array.length - 1;
      }

      if (self.prop('type') === 'radio') {
        while (input_array.eq(new_index).prop('type') === 'radio') { new_index += move_direction; }
      }

      if ((e.keyCode === enter_key) &&
      (new_index < input_array.length) &&
      (input_array.eq(new_index).prop('type') === 'submit')) {
        if ($('#form').attr('data-no-submit-on-return') === 'true') {
            // hack for modal form with uld select in add_uld_modal partial
           return false;
          } 
        form.find('button').not('[data-dismiss]').first().click();
        form.find('button:first:not(.no-disable)').attr('disabled', true);
        return false;
      }

      const move_to = input_array.eq(new_index);
      move_to.focus().select();

      return false;
    }
  });
});
