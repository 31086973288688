document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#outboundTours-table'),
    columns: [
      { data: 'status' },
      { data: 'number', className: 'mono' },
      { data: 'created_at' },
      { data: 'start' },
      { data: 'destination' },
      { data: 'trucker_name' },
      { data: 'scheduled_masters_number', className: 'mono', orderable: false },
      { data: 'loaded_masters_number', className: 'mono', orderable: false },
    ],
    order: [[2, 'desc']],
  });
});
