$(function () {
  $.datetimepicker.setDateFormatter({
    parseDate(date, format) {
      const d = moment(date, format);
      const d_alt = moment(date, 'DDMMYYHHmm');

      if (d.isValid()) {
        return d.toDate();
      } else if (d_alt.isValid()) {
        return d_alt.toDate();
      } else {
        return false;
      }
    },
    formatDate(date, format) {
      return moment(date).format(format);
    },
  });

  jQuery.datetimepicker.setLocale('de');
  window.initializeDateTimeSelectors = function () {
    $('.timepicker').datetimepicker({
      step: 15,
      allowBlank: true,
      dayOfWeekStart: 1,
      defaultSelect: false,
      scrollMonth: false,
      scrollInput: false,
      format: 'YYYY-MM-DD HH:mm',
      formatTime: 'HH:mm',
      formatDate: 'YYYY-MM-DD',
    });

    $('.date').datetimepicker({
      timepicker: false,
      allowBlank: false,
      dayOfWeekStart: 1,
      defaultSelect: false,
      scrollMonth: false,
      scrollInput: false,
      format: 'YYYY-MM-DD',
      formatTime: 'HH:mm',
      formatDate: 'YYYY-MM-DD',
    });
  };

  return initializeDateTimeSelectors();
});
