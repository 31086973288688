document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#employees-table'),
    columns: [
      { data: 'status' },
      { data: 'name' },
      { data: 'company_id' },
      { data: 'type' },
      { data: 'depots_name', orderable: false },
      { data: 'last_logged_in_at' },
      { data: 'last_logged_in_via', className: 'hidden' },
      { data: 'created_at' },
    ],
    searchCols: [
      { search: 'active' },
    ],
    order: [[1, 'asc']],
  });
});
