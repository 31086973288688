document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#chooseWorkItemShipments-table'),
    columns: [
      { data: 'id', orderable: false },
      { data: 'hazard_class' },
      { data: 'number', className: 'mono' },
      { data: 'hawb_number', className: 'mono' },
      { data: 'master_number', className: 'mono' },
      { data: 'subawb_number', className: 'mono' },
      { data: 'manifest_number', className: 'mono' },
      { data: 'client_name' },
      { data: 'origin', className: 'mono' },
      { data: 'destination', className: 'mono' },
      { data: 'pieces', className: 'mono pieces' },
      { data: 'weight', className: 'mono' },
    ],
    order: [[0, 'desc']],
  });

  window.markAndUnmarkRowItems('chooseWorkItemShipments');
});
