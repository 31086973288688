document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#inboundShipments-table'),
    columns: [
      { data: 'status' },
      { data: 'hazard_class' },
      { data: 'number', className: 'mono' },
      { data: 'hawb_number', className: 'mono' },
      { data: 'manifest_number', className: 'mono' },
      { data: 'client_name' },
      { data: 'alt_refs' },
      { data: 'origin' },
      { data: 'destination' },
      { data: 'pieces', className: 'text-right mono' },
      { data: 'weight', className: 'text-right mono' },
      { data: 'depot_name' },
      { data: 'pieces_checkedin', className: 'text-center mono' },
      { data: 'pieces_checkedout', className: 'text-center mono' },
      { data: 'created_at', className: 'hidden' },
    ],
    order: [[14, 'desc']],
  });
});
