$(document).ready(function() {
  $('.mawb-number-input').on('keyup paste', function(event) {
    setTimeout((function() {
      var value;
      var validationResult;
      var errorClass;
      var errorText;
      var errorElement;

      value = this.value;
      this.value = window.masterHelper.prettifyMasterNumber(value);

      validationResult = window.masterHelper.validateMasterNumber(this.value);
      errorClass = validationResult[0];
      errorText = validationResult[1];

      errorElement = document.getElementById(this.dataset.errorElementId);
      errorElement.innerHTML = errorText;
      errorElement.classList.remove("red");
      errorElement.classList.remove("green");
      errorElement.classList.add(errorClass);

    }).bind(this), 0);
  });
});
