/*
 * decaffeinate suggestions:
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

function columnSumInteger(columnData) {
  return columnData.reduce(
    ((a, b) => a + (parseInt(b, 10) || 0)), 0,
  ) || 0;
}

function columnSumFloat(columnData) {
  return columnData.reduce(
    (
      (a, b) => a + parseFloat(b.replace(/[^0-9,]/g, '').replace(',', '.'))
    ), 0.0,
  ).toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
}

function columnSumWeight(columnData) {
  return `${columnSumFloat(columnData)} kg`;
}

function columnSum(sumType, column) {
  switch (sumType) {
    case 'integer': return columnSumInteger(column.data());
    case 'float': return columnSumFloat(column.data());
    case 'weight': return columnSumWeight(column.data());
    default: return columnSumInteger(column.data());
  }
}

function ajaxDataTableLoadingScreen(settings) {
  if (settings.jqXHR != null) {
    settings.jqXHR.abort();
  }
  $('.dataTables_wrapper').addClass('loading');
}

function endAjaxDataTableLoadingScreen() {
  $('.dataTables_wrapper').removeClass('loading');
  $('.dataTables_empty').attr('colspan', '100%');
}

function getFilterForDatatableColumn(columnName) {
  return $(`.datatable-filter[data-column="${columnName}"]`);
}

document.addEventListener('DOMContentLoaded', () => {
  /*
   Initialize a jquery-datatable
   options['columns'] must match the order in which the headers are defined
   'data' attribute of column elements must match the hash key from Datatable#data response
   Search and filter functionality is provided like this
   Column headers with search functionality need
   the attribute `data-datatables-column`.
   The name in that attribute has to match the attribute `data-column`
   in the input (with the css class `.filter` to define the relationship.
   When the input in the filter changes, a search is executed (which redraws the whole table).

   Styling of a row can be adjusted by supplying data of a row with `row_css_classes`.
  */
  window.ajaxDataTableWithFilter = function (options) {
    const { $element } = options;
    const buttons = [
      { text: 'Filter', action() { return window.toggleWrapper(); } },
    ];
    let dataSrc = 'data';
    if (options.dataSrc !== undefined) { dataSrc = options.dataSrc; }
    let paging = true;
    if (options.paging !== undefined) { paging = options.paging; }

    $element.DataTable({
      ajax: {
        url: $element.data('source'),
        dataSrc,
      },
      buttons,
      ...(options.columns && { columns: options.columns }),
      createdRow: options.createdRow,
      dom: "<'row'<'col-sm-12'Blp>>"
        + "<'row'<'col-sm-12'tr>>"
        + "<'row'<'col-sm-12'p>>",
      drawCallback() {
        const api = this.api();
        const topFilterInputs = [];

        api.columns().toArray()[0].forEach((columnIndex) => {
          const column = api.column(columnIndex);
          const $filter = getFilterForDatatableColumn($(column.header()).data('datatables-column'));

          const filterId = $filter.attr('id');
          const $panelBody = $filter.closest('.panel-body');

          if ($filter.val()) {
            if (!$(`[data-placeholder-for='${filterId}']`).length) {
              $('<div/>', { 'data-placeholder-for': filterId }).hide().insertBefore($panelBody);
            }

            $panelBody.addClass('highlight');
            $panelBody.attr('data-pinned', true);
            topFilterInputs.push($panelBody);
            return;
          } if ($panelBody.data('pinned')) {
            const $placeholder = $(`[data-placeholder-for='${filterId}']`);

            $panelBody.removeClass('highlight');
            $panelBody.attr('data-pinned', false);
            $panelBody.insertAfter($placeholder);
            $placeholder.remove();
          }
        });

        $('#filter_group_1').prepend(topFilterInputs);

        $('#filterbar_panel .datatable-filter').each((_index, filterInput) => {
          if (filterInput.type === 'text') {
            filterInput.select();
            return false;
          }
        });

        endAjaxDataTableLoadingScreen();
      },
      language: {
        url: '/dataTables/german.json',
      },
      lengthChange: true,
      order: options.order,
      paging,
      pagingType: 'full_numbers',
      pageLength: 25,
      preDrawCallback: ajaxDataTableLoadingScreen,
      rowCallback(row, data) {
        if (window.markedRowIds.includes(data.DT_RowId && data.DT_RowId.toString())) {
          $(row).hide();
        }

        if (data.row_css_classes) {
          $(row).addClass(data.row_css_classes);
        }
      },
      searching: true,
      serverSide: true,
      stateLoaded(settings, data) {
        const api = this.api();
        data.columns.forEach((item, columnIndex) => {
          // Write search value back to filter input
          const columnData = data.columns[columnIndex];
          const columnName = $(api.column(columnIndex).header()).data('datatables-column');
          const $filterInput = getFilterForDatatableColumn(columnName);
          const columnValue = columnData.search.search;

          $filterInput.val(columnValue);
        });

        $('.panel').each((_panelIndex, panelElement) => {
          $(panelElement).find('[data-storagekey]').each((_storageIndex, storageElement) => {
            const storageKey = $(storageElement).data('storagekey');
            const panelState = localStorage.getItem(storageKey);
            const panel = $(storageElement).closest('.panel').find('.panel-collapse');

            if (panel && panelState) {
              $(panel).collapse(panelState);
            }
          });
        });
      },

      stateSave: true,
      searchCols: options.searchCols,

      initComplete() {
        // Register event handlers so that input in filters executes the search
        $('.datatable-filter:input').on('change keyup', (e) => {
          e.stopPropagation();
          if ((e.type === 'keyup') && (e.which !== 13)) { return; }
          const columnNameForFilter = $(e.target).data('column');
          const selector = `[data-datatables-column="${columnNameForFilter}"]`;
          const searchValue = $(e.target).val();

          let filtersAreValid = true;
          $('.datatable-filter:input').each((index, inputField) => {
            if (inputField.checkValidity() === false) {
              filtersAreValid = false;
            }
          });

          if (filtersAreValid) {
            this.api().column(selector).search(searchValue).draw();
          }
        });
      },
    });
  };

  // Initialize a serverside powered jquery-datatable - without filters
  // options['columns'] must match the order in which the headers are defined.
  //   'data' attribute of column elements must match the hash key from Datatable#data response
  //   When not provied then the column headers
  //   require the `data-data` attribute (see datatables.net Docs about HTML5 data-attributes).
  window.ajaxDataTable = function (options) {
    const { $element } = options;
    let dataSrc = 'data';
    if (options.dataSrc !== undefined) { dataSrc = options.dataSrc; }
    let paging = true;
    if (options.paging !== undefined) { paging = options.paging; }
    let pageLength = 25;
    if (options.pageLength !== undefined) { pageLength = options.pageLength; }

    $element.DataTable({
      ajax: {
        url: $element.data('source'),
        dataSrc,
      },
      buttons: [],
      ...(options.columns && { columns: options.columns }),
      createdRow: options.createdRow,
      dom: "<'row'<'col-sm-12'Blp>>"
        + "<'row'<'col-sm-12'tr>>"
        + "<'row'<'col-sm-12'p>>",
      drawCallback: endAjaxDataTableLoadingScreen,
      footerCallback() {
        if (!options.footerOptions) { return; }

        const api = this.api();
        const result = [];
        Object.getOwnPropertyNames(options.footerOptions).forEach((columnName) => {
          const sumType = options.footerOptions[columnName];
          const column = api.column(`[data-datatables-column="${columnName}"]`);
          const sumContent = columnSum(sumType, column);

          result.push($(column.footer()).html(sumContent));
        });
      },
      language: {
        url: '/dataTables/german.json',
      },
      lengthChange: true,
      ...(options.order && { order: options.order }),
      paging,
      pagingType: 'full_numbers',
      pageLength,
      preDrawCallback: ajaxDataTableLoadingScreen,
      rowCallback(row, data) {
        if (data.row_css_classes) {
          $(row).addClass(data.row_css_classes);
        }
      },
      serverSide: true,
      stateSave: true,
    });
  };
});
