import { Controller } from 'stimulus';

export default class ResourceFormController extends Controller {
  static targets = [
    'dimensionInput',
    'palletCountInput',
    'overview',
    'template',
    'form',
    'destroy',
    'input',
    'label',
    'dimensionSum',
    'palletCount',
  ];

  connect() {
    this.updateOverview('euro_pallet');
    this.updateOverview('single_use_pallet');
  }

  updateOverview(eventOrFormType) {
    let formType;
    if (typeof eventOrFormType === 'string') {
      formType = eventOrFormType;
    } else {
      const formTypeElement =
        eventOrFormType.target.closest('[data-form-type]');
      if (!formTypeElement) {
        return;
      }
      formType = formTypeElement.dataset.formType;
    }

    const dimensionInputs = this.dimensionInputTargets.filter(
      (input) =>
        input.closest('[data-form-type]').dataset.formType === formType,
    );

    const palletCountInput = this.palletCountInputTargets.find(
      (input) =>
        input.closest('[data-form-type]').dataset.formType === formType,
    );

    const overviewElement = this.overviewTargets.find(
      (overview) =>
        overview.closest('[data-form-type]').dataset.formType === formType,
    );

    const dimensionSumElement = this.dimensionSumTargets.find(
      (overview) =>
        overview.closest('[data-form-type]').dataset.formType === formType,
    );

    const palletCountElement = this.palletCountTargets.find(
      (overview) =>
        overview.closest('[data-form-type]').dataset.formType === formType,
    );

    if (!palletCountInput || !overviewElement) {
      return;
    }

    const dimensionSum = dimensionInputs.reduce(
      (sum, input) => sum + (parseInt(input.value, 10) || 0),
      0,
    );

    const palletCount = parseInt(palletCountInput.value, 10) || 0;

    if (dimensionSum > palletCount) {
      overviewElement.classList.add('resource-form__custom-error');
    } else {
      overviewElement.classList.remove('resource-form__custom-error');
    }

    dimensionSumElement.innerHTML = dimensionSum;
    palletCountElement.innerHTML = palletCount;
  }

  addInput(event) {
    event.preventDefault();

    const formElement = event.target.closest('[data-form-type]');
    const { formType } = formElement.dataset;
    const template = this.templateTargets.find(
      (t) => t.dataset.formType === formType,
    );
    const content = template.content.cloneNode(true);
    const form = this.formTargets.find((f) => f.dataset.formType === formType);

    const currentIndex = parseInt(template.dataset.currentIndex, 10);

    content.querySelectorAll('input, label').forEach((element) => {
      if (element.hasAttribute('name')) {
        element.setAttribute(
          'name',
          element.getAttribute('name').replace('__INDEX__', currentIndex),
        );
      }
      if (element.hasAttribute('id')) {
        element.setAttribute(
          'id',
          element.getAttribute('id').replace('__INDEX__', currentIndex),
        );
      }
      if (element.tagName === 'LABEL' && element.hasAttribute('for')) {
        element.setAttribute(
          'for',
          element.getAttribute('for').replace('__INDEX__', currentIndex),
        );
      }
    });

    template.dataset.currentIndex = currentIndex + 1;

    form.appendChild(document.importNode(content, true));
  }

  removeInput(event) {
    event.preventDefault();
    const item = event.target.closest('.field');
    const destroyInput = this.destroyTargets.find((target) =>
      item.contains(target),
    );
    if (destroyInput) {
      item.remove();
      this.updateOverview('euro_pallet');
      this.updateOverview('single_use_pallet');
    }
  }
}
