document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#inboundTourLoads-table'),
    columns: [
      { data: 'master_status' },
      { data: 'number', className: 'text-right mono' },
      { data: 'master_number', className: 'text-right mono' },
      { data: 'created_at' },
      { data: 'master_airline_name', orderable: false },
      { data: 'master_origin', className: 'text-right mono' },
      { data: 'master_destination', className: 'text-right mono' },
      { data: 'master_client_name' },
      { data: 'master_pieces', className: 'text-right mono' },
      { data: 'master_weight', className: 'text-right mono' },
      { data: 'pieces', className: 'text-right mono' },
      { data: 'weight', className: 'text-right mono' },
      { data: 'status', orderable: false },
      { data: 'id', orderable: false },
    ],
    order: [[3, 'desc']],
    paging: false,
  });

  window.ajaxDataTableWithFilter({
    $element: $('#receivedInboundTourLoads-table'),
    columns: [
      { data: 'master_status' },
      { data: 'number', className: 'text-right mono' },
      { data: 'master_number', className: 'text-right mono' },
      { data: 'created_at' },
      { data: 'master_airline_name', orderable: false },
      { data: 'master_origin', className: 'text-right mono' },
      { data: 'master_destination', className: 'text-right mono' },
      { data: 'master_client_name' },
      { data: 'master_pieces', className: 'text-right mono' },
      { data: 'master_weight', className: 'text-right mono' },
      { data: 'pieces', className: 'text-right mono' },
      { data: 'weight', className: 'text-right mono' },
      { data: 'status', orderable: false },
      { data: 'id', orderable: false },
    ],
    order: [[3, 'desc']],
    paging: false,
  });

  window.ajaxDataTableWithFilter({
    $element: $('#chooseInboundTourLoads-table'),
    columns: [
      { data: 'id', orderable: false },
      { data: 'number', className: 'mono' },
      { data: 'master_number', className: 'mono' },
      { data: 'created_at' },
      { data: 'master_airline_name', orderable: false },
      { data: 'master_origin', className: 'mono' },
      { data: 'master_destination', className: 'mono' },
      { data: 'master_client_name' },
      { data: 'master_pieces', className: 'text-right mono' },
      { data: 'master_weight', className: 'text-right mono' },
      { data: 'pieces', className: 'text-right mono' },
      { data: 'weight', className: 'text-right mono' },
    ],
    order: [[3, 'desc']],
  });

  window.markAndUnmarkRowItems('chooseInboundTourLoads');
});
