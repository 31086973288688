import format from './src/utils/format';

export const getClonedNumberInput = (input) => {
  const clone = input.cloneNode()
  clone.type = 'number'
  return clone
}

export const getNumberValidationMessage = (input) => {
  const numberInputClone = input.cloneNode()
  numberInputClone.type = 'number'
  return numberInputClone.validationMessage
}

export const handleInputChange = (target) => {
  if (target.matches('.integer, [data-format="integer"]')) {
    if (target.type === 'number' && process.env.RAILS_ENV === 'development') {
      // It is possible to enter the letter 'e' in a number input, but the value is invalid and is read as ''
      // This results in the entire value being cleared if you type the letter 'e'
      console.warn(
        'Chicargo warning: Input type when using "integer" class for automatically cleaning values should be "text" to avoid invalid inputs being cleared completely',
      );
    }
    target.value = format.integer(target.value);
    target.setCustomValidity(getNumberValidationMessage(target))
  }
};

export const listenForInputChanges = (onChange) => {
  document.addEventListener('input', (e) => {
    const target = e.target;
    onChange(target);
  });
};

document.addEventListener('DOMContentLoaded', () => {
  // Submit only once
  $('form').on('submit', function (e) {
    if ($(this).hasClass('submitted')) {
      e.preventDefault();
    } else {
      $(this).addClass('submitted');
    }
  });

  // Auto-select first input in full forms
  $('form.full input[type=text], form.full select')
    .first()
    .trigger('focus')
    .trigger('select');

  listenForInputChanges(handleInputChange);

  // Float
  $(document)
    .on('input change', '.float', function (e) {
      this.value = this.value.replace(/,/, '.').replace(/[^0-9\.]/gi, '');
    })
    .on('focus', function () {
      $(this).trigger('select');
    });

  // Currency
  $(document)
    .on('input change', '.currency', function (e) {
      this.value = this.value.replace(/,/, '.').replace(/[^0-9\.\-]/gi, '');
    })
    .on('focus', function () {
      $(this).trigger('select');
    });

  // Integer-Range
  $(document)
    .on('input change', '.integer-range', function () {
      this.value = this.value.replace(/[^0-9\-]/gi, '');
    })
    .on('focus', function () {
      $(this).trigger('select');
    });

  // Float-Range
  $(document)
    .on('input change', '.float-range', function () {
      this.value = this.value.replace(/[\,]/gi, '.');
      this.value = this.value.replace(/[^0-9\-\.]/gi, '');
    })
    .on('focus', function () {
      $(this).trigger('select');
    });

  // Alpha integer
  $(document).on('input change', '.alpha-integer', function () {
    this.value = this.value.replace(/[^0-9a-zäöüÄÖÜß\n]/gi, '');
  }); // FIXME backspace is broken

  // License Plate
  $(document).on('input change', '.license-plate', function () {
    this.value = this.value.replace(/[^0-9a-zäöüÄÖÜß\ \-\n]/gi, '');
  });
  $(document).on(
    'input change',
    '.license-plate-input .selectize-input input',
    function () {
      this.value = this.value.replace(/[^0-9a-zäöüÄÖÜß\ \-\n]/gi, '');
    },
  );

  $(document).on('click', 'input.select-all', function () {
    this.select();
  });
});
