document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTable({
    $element: $('#nestedBillingItems-table'),
    columns: [
      { data: 'status' },
      { data: 'number', className: 'mono' },
      { data: 'recaptured_at' },
      { data: 'billable' },
      { data: 'amount', className: 'text-right mono' },
      { data: 'user_name' },
      { data: 'performed_at', className: 'text-right' },
    ],
    order: [[6, 'desc']],
  });
});
