const stationAutocompleteSelector = '.station, [data-autocomplete="station"]';

const autocompleteStations = (stations) => {
  $(stationAutocompleteSelector)
    .autocomplete({
      delay: 0,
      autoFocus: true,
      source: stations,
    })
    .each((i, el) => {
      $(el).data('ui-autocomplete')._renderItem = (ul, item) => 
        $('<li>')
          .append(
            `<a>${item.value}<br>
              <small>${item.name}, ${item.country}</small>
            </a>`,
          )
          .appendTo(ul);
    });
};

document.addEventListener('DOMContentLoaded', () => {
  const stationsField = document.querySelector(stationAutocompleteSelector);
  if (stationsField) {
    const stationsJson = stationsField.dataset.stations;
    if (!stationsJson) {
      console.warn('stations dataset missing');
    } else {
      const stations = JSON.parse(stationsJson);

      $(document).ajaxComplete(() => autocompleteStations(stations));
      autocompleteStations(stations);
    }
  }
});
