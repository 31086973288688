document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#driverRegistrations-table'),
    columns: [
      { data: 'edit_link', className: 'text-right', orderable: false },
      { data: 'status' },
      { data: 'license_plate' },
      { data: 'depot_name' },
      { data: 'trucker' },
      { data: 'hauler' },
      { data: 'driver_present_at', searchable: true, orderable: true },
      // { data: 'trucker_status', searchable: true },
    ],
    order: [[6, 'desc']],
  });

  document.querySelectorAll('tr.link_tpa').forEach((element) => {
    element.addEventListener('click', (e) => {
      e.preventDefault();

      const form = element.closest('form');
      form.action = element.dataset.href;
      form.submit();
    });
  });
});
