export const urlParamValue = (key) => {
  // note that URLSearchParams is not available in js-dom testing env
  // so can't write a test for this
  const searchParams = new URLSearchParams(window.location.search);

  return searchParams.get(key);
};

const urlHelper = {
  paramValue: urlParamValue,
};

export default urlHelper;
