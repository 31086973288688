document.addEventListener('DOMContentLoaded', () => {
  // Trigger confirm modal before printing when no destination is given
  $(document).on('click', '.confirm-destination-present', function (e) {
    e.preventDefault();

    const destination = $(this).data('destination');
    const withoutHawb = $(this).data('without-hawb');

    if (withoutHawb || destination) {
      if ($(this).data('custom-print')) {
        window.customPrint();
      } else {
        $(this).closest('form').submit();
      }
    } else {
      dataConfirmModal.confirm({
        text: I18n.t('miscellaneous.label_print_destination_confirmation'),
        onConfirm: () => {
          if ($(this).data('custom-print')) {
            window.customPrint();
          } else {
            $(this).closest('form').submit();
          }
        },
        onCancel: () => {
          $('#label-print-modal-dismiss-button').click();
        },
      });
    }
  });

  const customPrintForm = document.getElementById('custom-print');
  if (customPrintForm) {
    $('input[type=radio]').change(function () {
      switch ($(this).val()) {
        case 'hawb':
          $('.field.hawb').show();
          $('.field.mawb').val('').hide();
          break;
        case 'mawb':
          $('.field.mawb').show();
          $('.field.hawb').val('').hide();
          break;
        default:
          return;
      }
    });

    $('#destination').blur(function () {
      $('#print-button').attr('data-destination', $(this).val());
    });

    $('form#custom-print #pieces').on('change', function (e) {
      const pieces_val = $(this).val();
      return $('form#custom-print #print_label_which').val(pieces_val);
    });

    window.customPrint = function () {
      const airlinesDataset = customPrintForm.dataset.airlines;
      if (!airlinesDataset) {
        console.warn('Airlines data not provided to form.');
        return;
      }

      if (!$('form')[0].reportValidity()) {
        return;
      }

      $('.upcase').val(function () {
        return this.value.toUpperCase();
      });

      const airlines = JSON.parse(airlinesDataset);

      const data = {
        type: '',
        number: '',
        client_id: $('#client').val(),
        client_name: $('#client option:selected').text(),
        airline: airlines[$('#master_number').val().slice(0, 3)],
        hawb_number: $('#house_awb_number').val(),
        formatted_hawb_number: $('#house_awb_number').val(),
        master_number: $('#master_number').val(),
        formatted_number: $('#master_number')
          .val()
          .replace(/(\d{3})(\d{8})/, '$1-$2'),
        pieces: $('#pieces').val(),
        weight: $('#weight').val(),
        formatted_weight: $('#weight').val().replace('.', ',') + ' kg',
        origin: $('#origin').val(),
        destination: $('#destination').val(),
        secured: true,
        title: $('#client option:selected').text(),
        custom_print: true,
        amount: $('#print_label_which').val(),
        mawb_checked: $('#type_mawb')[0].checked,
      };

      if ($('#type_mawb')[0].checked) {
        data['number'] = data['master_number'];
      }

      $.ajax({
        type: 'POST',
        url: '/custom_print/prepare_zpl',
        data,
        content_type: 'application/json',
      }).done((zpl) => window.printSizedLabels(zpl, 'standard'));
    };
  }
});
