import { urlParamValue } from './src/utils/url_helper';

// billing items in each group use the same specific form
// billing items not included in any group use the default form
const billingItemFormGroups = [
  [''], // nothing selected
  ['tour_75', 'tour_semi'],
  ['package_clasp', 'document_consolidation'],
  ['palletizing'],
  ['strapping'],
  ['photo_documentation_truck'],
  ['provisioning_worker'],
  ['provisioning_checker'],
  ['working_time'],
  ['measuring_and_weighing'],
];

export const getIsFormChangeNeeded = (currentBillable, selectedBillable) => {
  const [currentBillableGroupIndex, selectedBillableIndex] = [
    currentBillable || '',
    selectedBillable || '',
  ].map(
    (billableName) =>
      billingItemFormGroups.find((group) => group.includes(billableName)) ||
      '-1',
  );

  const isSameFormGroup = currentBillableGroupIndex === selectedBillableIndex;
  return !isSameFormGroup;
};

const handleBillableSelection = (billableSelect) => {
  const currentPath = window.location.pathname;

  billableSelect.on('change', function () {
    const selectedBillable = $(this).val();
    const currentBillable = urlParamValue('form_for');
    const needToChangeForm = getIsFormChangeNeeded(
      currentBillable,
      selectedBillable,
    );

    if (needToChangeForm) {
      window.location.href = `${currentPath}?form_for=${selectedBillable}`;
    }
  });
};

const handleSpxBillingItemFormChanges = () => {
  $('#billing_item_performed_at, #billing_item_amount').on('change', () => {
    let date = moment($('#billing_item_performed_at').val()).format(
      'YYYY-MM-DD',
    );
    let pieces_secured = $('#billing_item_amount').val();
    if (date != null && pieces_secured.length > 0) {
      $.ajax({
        url: 'default_values',
        data: { values: { date: date, pieces_secured: pieces_secured } },
        method: 'GET',
        success(response) {
          $('#billing_item_working_time').val(response.working_time);
          $('#billing_item_costs_material').val(response.material_cost);
        },
      });
    }
  });
};

document.addEventListener('DOMContentLoaded', () => {
  const billableSelect = $('select#billing_item_billable');
  const spxBillingItemForm = $('[data-form-handling="billing-item-spx"]');

  if (billableSelect.length) {
    handleBillableSelection(billableSelect);
  }

  if (spxBillingItemForm.length) {
    handleSpxBillingItemFormChanges();
  }
});
