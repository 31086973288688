/* eslint-env jquery */
/* eslint-disable import/prefer-default-export */

export const handleDepotEmail = (form) => {
  const depotEmails = form.data('depotEmails');
  const depotSelect = form.find('select[name=depot_id], select[name=depot]');
  const emailInput = form.find('input[name=email]');

  // populate depot-email only if there is no initial user-email set (e.g. Lagerarbeiter)
  if (emailInput.val() === '') {
    // eslint-disable-next-line func-names -- jquery this
    $(depotSelect).on('change', function () {
      const selectedDepotId = $(this).val();
      emailInput.val(depotEmails[selectedDepotId]);
    });
    depotSelect.trigger('change');
  }
};
