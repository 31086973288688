$(() => {
  $(document).on('shown.bs.modal', () => {
    $(this).find('[autofocus]').focus();
  });
});

window.ErrorMessageModal = (
  messages,
  title = 'Es ist ein Fehler aufgetreten. Sorry!',
) => {
  const errorMessageModal = `
    <div class="modal-header">
      <button class="close" aria-label="Close" data-dismiss="modal" type="button">
        <span aria-hidden="true">x</span>
      </button>
      <h2 id="gridSystemModalLabel" class="modal-title red">${title}</h2>
    </div>
    <div class="modal-body">
      ${messages
        .map((message) => `<div class="error">${message}</div>`)
        .join('')}
    </div>
    <div class="modal-footer center">
      <button class="btn btn-default" data-dismiss="modal" type="button">Ok</button>
    </div>
  `;

  $('#error-message-modal .modal-content').html(errorMessageModal);
  $('#error-message-modal').modal('show');
};
