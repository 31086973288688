/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#workItems-table'),
    columns: [
      { data: 'status' },
      { data: 'number', className: 'mono' },
      { data: 'workable' },
      { data: 'client_name' },
      { data: 'depot_name' },
      { data: 'parent_master_number', className: 'mono', orderable: false },
      { data: 'parent_shipment_number', className: 'mono', orderable: false },
      { data: 'parent_checkedin_progress', className: 'text-center', orderable: false },
      { data: 'parent_secured_progress', className: 'text-center', orderable: false },
      { data: 'parent_pieces', className: 'text-right mono', orderable: false },
      { data: 'parent_weight', className: 'text-right mono', orderable: false },
      { data: 'created_at', className: 'text-right' },
    ],
    order: [[11, 'desc']],
    searchCols: [
      { search: 'pending' },
    ],
  });

  /*
  Reload table content often enough that users do not miss newest work items
  but rarely enough that they are not completely irritated.
   */
  setInterval(() => {
    $('#workItems-table').DataTable().ajax.reload();
  }, 5 * 60 * 1000);
});
