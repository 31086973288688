document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#airlines-table'),
    columns: [
      { data: 'prefix', className: 'mono' },
      { data: 'name' },
      { data: 'inbound_ground_handling_agents_name', orderable: false },
      { data: 'outbound_ground_handling_agents_name', orderable: false },
      { data: 'tracking_url', orderable: false },
      { data: 'edit', className: 'text-right', orderable: false },
    ],
    order: [[0, 'asc']],
  });
});
