document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#inboundTours-table'),
    columns: [
      { data: 'status' },
      { data: 'number', className: 'mono' },
      { data: 'created_at' },
      { data: 'start' },
      { data: 'destination' },
      { data: 'trucker_name' },
      { data: 'scheduled_masters_number', className: 'mono', orderable: false },
      { data: 'scheduled_customer_names', orderable: false },
      { data: 'updated_at', className: 'hidden' },
    ],
    searchCols: [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      { search: '24_h_ago' },
    ],
    order: [[2, 'desc']],
  });
});
