document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#chooseOutboundPickupShipments-table'),
    columns: [
      { data: 'id' },
      { data: 'number', className: 'mono' },
      { data: 'manifest_number', className: 'mono' },
      { data: 'hawb_number', className: 'mono' },
      { data: 'client_name' },
    ],
    order: [[4, 'asc']],
  });

  return window.markAndUnmarkRowItems('chooseOutboundPickupShipments');
});
