document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#outboundPickups-table'),
    columns: [
      { data: 'status' },
      { data: 'number', className: 'mono' },
      { data: 'destination' },
      { data: 'license_plate' },
      { data: 'arrival_date' },
      { data: 'depot_name' },
      { data: 'trucker' },
      { data: 'hauler' },
      { data: 'manifest_number' },
    ],
    order: [[0, 'desc'], [4, 'desc']],
  });
});
