document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTable({
    $element: $('#outboundMasterShipments-table'),
    columns: [
      { data: 'hazard_class' },
      { data: 'number', className: 'mono' },
      { data: 'hawb_number', className: 'mono' },
      { data: 'subawb_number', className: 'mono' },
      { data: 'manifest_number', className: 'mono' },
      { data: 'client_name' },
      { data: 'origin', className: 'text-right mono' },
      { data: 'destination', className: 'text-right mono' },
      { data: 'pieces', className: 'text-right mono' },
      { data: 'weight', className: 'text-right mono' },
      { data: 'depot_name', orderable: false },
      { data: 'pieces_checkedin', orderable: false },
      { data: 'pieces_secured', orderable: false },
      { data: 'outbound_packs_count', orderable: false },
      { data: 'pickedup_status', orderable: false },
      { data: 'outbound_packs_delivered', orderable: false },
      { data: 'id', orderable: false },
    ],
    order: [[2, 'asc']],
  });

  window.ajaxDataTableWithFilter({
    $element: $('#chooseOutboundMasterShipments-table'),
    columns: [
      { data: 'id', orderable: false, className: 'hidden' },
      { data: 'hazard_class' },
      { data: 'number', className: 'mono' },
      { data: 'hawb_number', className: 'mono' },
      { data: 'mawb_number', className: 'mono' },
      { data: 'subawb_number', className: 'mono' },
      { data: 'manifest_number', className: 'mono' },
      { data: 'client_name' },
      { data: 'origin', className: 'text-right mono' },
      { data: 'destination', className: 'text-right mono' },
      { data: 'pieces', className: 'text-right mono' },
      { data: 'weight', className: 'text-right mono' },
      { data: 'pieces_secured' },
    ],
    order: [[0, 'desc']],
  });

  window.markAndUnmarkRowItems('chooseOutboundMasterShipments');
});
