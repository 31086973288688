document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#admin_authorized_devices-table'),
    columns: [
      { data: 'name' },
      { data: 'browser' },
      { data: 'ip', className: 'mono' },
      { data: 'last_login_at' },
      { data: 'activated_at' },
      { data: 'activate' },
      { data: 'edit', orderable: false },
      { data: 'destroy', orderable: false },
    ],
    order: [[4, 'desc']],
  });
});
