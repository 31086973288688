document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#ground_handling_agents-table'),
    columns: [
      { data: 'name' },
      { data: 'acronym', className: 'mono' },
      { data: 'delivery_address' },
      { data: 'fair_at_link_identifier', className: 'mono' },
      { data: 'inbound_airlines_id', orderable: false },
      { data: 'outbound_airlines_id', orderable: false },
      { data: 'edit', orderable: false },
      { data: 'destroy', orderable: false },
    ],
    order: [[0, 'asc']],
  });
});
