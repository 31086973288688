document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#outboundMasters-table'),
    columns: [
      { data: 'status' },
      { data: 'human', className: 'mono' },
      { data: 'lat' },
      { data: 'consolidation_type' },
      { data: 'alt_refs' },
      { data: 'airline_name' },
      { data: 'origin' },
      { data: 'destination' },
      { data: 'pieces' },
      { data: 'weight' },
      { data: 'client_name' },
      { data: 'depot_name' },
      { data: 'secured_progress', className: 'text-center', orderable: false },
      { data: 'packed_progress', className: 'text-center', orderable: false },
      { data: 'pouch_captured', className: 'text-center' },
      { data: 'created_at', className: 'hidden' },
    ],
    order: [[15, 'desc']],
  });
});
