/* eslint-env jquery */

// Mark buttons for setting dates, e.g. 'last month', 'this week' as
// active or inactive depending on dates entered in date input fields
// eslint-disable-next-line import/prefer-default-export
export const handleDateInputs = (form) => {
  const startDateInput = form.find('input.date[name=start]');
  const endDateInput = form.find('input.date[name=end]');
  const dateSetterButtons = form.find('button[data-form-action=setDateRange]');

  // eslint-disable-next-line func-names -- can't use arrow function with jquery 'this'
  dateSetterButtons.click(function () {
    startDateInput.val($(this).data('start-date'));
    endDateInput.val($(this).data('end-date'));
    startDateInput.trigger('change');
    endDateInput.trigger('change');
  });

  const markMatchingButtonsAsActive = () => {
    const mark = (el) => {
      const $el = $(el);
      if (
        startDateInput.val() === $el.data('start-date') &&
        endDateInput.val() === $el.data('end-date')
      ) {
        $el.addClass('active');
      } else {
        $el.removeClass('active');
      }
    };
    Array.from(dateSetterButtons).forEach((button) => mark(button));
  };
  markMatchingButtonsAsActive();

  startDateInput.change(() => markMatchingButtonsAsActive());
  endDateInput.change(() => markMatchingButtonsAsActive());
};
