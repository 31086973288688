document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#chooseInboundShipments-table'),
    columns: [
      { data: 'id', orderable: false },
      { data: 'number', className: 'mono' },
      { data: 'hawb_number', className: 'mono' },
      { data: 'mawb_number', className: 'mono' },
      { data: 'manifest_number', className: 'mono' },
      { data: 'client_name' },
      { data: 'consigner' },
      { data: 'origin' },
      { data: 'destination' },
      { data: 'pieces', className: 'text-right mono' },
      { data: 'weight', className: 'text-right mono' },
      { data: 'depot_name' },
      { data: 'pieces_checkedin', className: 'text-center mono' },
    ]
  });

  window.markAndUnmarkRowItems('chooseInboundShipments');
});
