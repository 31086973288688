window.shipmentHelper = {
  togglePiecesWithHazardousBatteries: function(new_value) {
    inputField = document.getElementById('outbound_shipment_pieces_with_hazardous_batteries');
    if (new_value === 'lithium_batteries') {
      inputField.disabled = false;
      inputField.classList.remove('disabled');
    }
    else {
      inputField.value = '';
      inputField.disabled = true;
      inputField.classList.add('disabled');
    }
  }
}
