document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#chooseOutboundArrivalShipments-table'),
    columns: [
      { data: 'id', orderable: false, className: 'hidden' },
      { data: 'hazard_class' },
      { data: 'number', className: 'mono' },
      { data: 'hawb_number', className: 'mono' },
      { data: 'master_number', className: 'mono' },
      { data: 'subawb_number', className: 'mono' },
      { data: 'manifest_number', className: 'mono' },
      { data: 'client_name' },
      { data: 'origin', className: 'mono' },
      { data: 'destination', className: 'mono' },
      { data: 'pieces', className: 'text-right mono' },
      { data: 'weight', className: 'text-right mono' },
      { data: 'pieces_secured', className: 'text-center' },
    ],
    order: [[2, 'asc']],
  });

  window.markAndUnmarkRowItems('chooseOutboundArrivalShipments');
});
