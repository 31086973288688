// The following configuration is provided by elevio. It does not make much sense
// to change it just because eslint complains.

/* eslint-disable */
!function(e,l,v,i,o,n){e[i]||(e[i]={}),e[i].account_id=n;var g,h;g=l.createElement(v),g.type="text/javascript",g.async=1,g.src=o+n,h=l.getElementsByTagName(v)[0],h.parentNode.insertBefore(g,h);e[i].q=[];e[i].on=function(z,y){e[i].q.push([z,y])}}(window,document,"script","_elev","https://cdn.elev.io/sdk/bootloader/v4/elevio-bootloader.js?cid=","576abec6eb61f");

window._elev.on('load', _elev =>
   _elev.setSettings({
     hideLauncher: true})
);
/* eslint-enable */
