window.masterHelper = {
  prettifyLatTime: function(str) {
    var hours, minutes, ret;
    str = str.replace(/[^0-9]/g, '');
    switch (str.length) {
    case 3:
      ret = ['0', str.slice(0, 1), ':', str.slice(1)].join('');
      break;
    case 4:
      ret = [str.slice(0, 2), ':', str.slice(2)].join('');
      break;
    default:
      ret = '';
    }
    hours = parseInt(ret.slice(0, 2), 10);
    minutes = parseInt(ret.slice(3, 5), 10);
    if (hours > 23 || minutes > 59) {
      return '';
    } else {
      return ret;
    }
  },

  prettifyMasterNumber: function(str) {
    var ret;
    ret = str.replace(/[^0-9]/g, '');
    if (ret.length >= 3) {
      return ret.slice(0, 3) + '-' + ret.slice(3, 11);
    }
    return ret;
  },

  validateMasterNumber: function(number) {
    var checkDigit;
    var errorText;
    var errorClass;

    sanitizedNumber = number.replace(/[^0-9]/g, '');
    checkDigit = parseInt(sanitizedNumber.slice(3, 10), 10) % 7;

    errorText = '';
    if (sanitizedNumber.match(/^\d{11}$/) === null) {
      errorText = 'Die MAWB-Nummer muss aus 11 Ziffern bestehen.';
      errorClass = 'red';
    }
    else if (parseInt(sanitizedNumber[10], 10) !== checkDigit) {
      errorText = 'Die Prüfziffer der MAWB-Nummer ist nicht korrekt. (' + checkDigit + ')';
      errorClass = 'red';
    }
    else {
      errorText = 'Länge und Prüfziffer der MAWB-Nummer sind korrekt.';
      errorClass = 'green';
    }

    return [errorClass, errorText];
  }
};
