document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#inboundMasters-table'),
    columns: [
      { data: 'status' },
      { data: 'number', className: 'mono' },
      { data: 'consolidation_type' },
      { data: 'alt_refs' },
      { data: 'airline_name' },
      { data: 'origin' },
      { data: 'destination' },
      { data: 'client_name' },
      { data: 'depot_name' },
      { data: 'pieces', className: 'mono text-right' },
      { data: 'weight', className: 'mono text-right' },
      { data: 'shipments_count', orderable: false, className: 'mono text-right' },
      { data: 'shipments_pieces', orderable: false, className: 'mono text-right' },
      { data: 'shipments_weight', orderable: false, className: 'mono text-right' },
      { data: 'shipments_checkedin', className: 'text-center' },
      { data: 'created_at', className: 'hidden' },
    ],
    order: [[12, 'desc']],
  });
});
