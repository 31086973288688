document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTable({
    $element: $('#inboundArrivalPendingShipmentLoads-table'),
    columns: [
      { data: 'shipment_hazard_class' },
      { data: 'shipment_number', className: 'mono' },
      { data: 'shipment_hawb_number', className: 'mono' },
      { data: 'shipment_client_name' },
      { data: 'shipment_pieces' },
      { data: 'destroy', orderable: false },
    ],
    order: [[1, 'desc']]
  });

  window.ajaxDataTable({
    $element: $('#inboundArrivalCheckedinShipmentLoads-table'),
    columns: [
      { data: 'shipment_hazard_class' },
      { data: 'shipment_number', className: 'mono' },
      { data: 'shipment_hawb_number', className: 'mono' },
      { data: 'shipment_client_name' },
      { data: 'shipment_pieces' },
      { data: 'destroy', orderable: false },
    ],
    order: [[1, 'desc']]
  });

  window.ajaxDataTable({
    $element: $('#inboundArrivalMasterLoads-table'),
    columns: [
      { data: 'status' },
      { data: 'number', className: 'mono' },
      { data: 'pieces', className: 'text-right' },
      { data: 'weight', className: 'text-right' },
      { data: 'destroy', orderable: false },
    ],
    order: [[2, 'desc']],
  });

  window.ajaxDataTableWithFilter({
    $element: $('#chooseInboundArrivalMasters-table'),
    columns: [
      { data: 'id' },
      { data: 'status' },
      { data: 'number', className: 'mono' },
      { data: 'pieces', className: 'text-right' },
      { data: 'weight', className: 'text-right' },
      { data: 'tour_number', className: 'mono' },
    ],
    order: [[3, 'desc']],
  });

  window.markAndUnmarkRowItems('chooseInboundArrivalMasters');
});
