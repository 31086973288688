$ ->
  return unless $('#attach_form, #merge_form, #bulk_update_form').length

  mirror_checkboxes = ->
    checkboxes = $('input[type=checkbox]:checked').map ->
      '<input type="hidden" name="' + this.name + '" value="' + this.value + '">'

    $('.button-row form')
      .find('input[name$="[]"]').remove().end()
      .append(checkboxes.get())

  $('table input[type=checkbox]').change(mirror_checkboxes)
  mirror_checkboxes()

  do_select = true
  $('a[data-select-all]').click ->
    $('table input[type=checkbox]').prop('checked', do_select)
    do_select = !do_select
    mirror_checkboxes()
    return false

  return unless $('#attach_form, #merge_form').length

  $('.pagination a').click ->
    $('.button-row form')
      .append('<input type="hidden" name="page" value="' + this.href.match(/page=(\d+)/)[1] + '">')
      .submit()
    return false
