document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#companies-table'),
    columns: [
      { data: 'name' },
      { data: 'slug', className: 'mono' },
      { data: 'logo', orderable: false },
      { data: 'address', orderable: false },
      { data: 'contact', orderable: false },
      { data: 'users_count', orderable: true },
    ],
    order: [[0, 'asc']],
  });
});
