document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#outboundShipments-table'),
    columns: [
      { data: 'status' },
      { data: 'hazard_class' },
      { data: 'number', className: 'mono' },
      { data: 'hawb_number', className: 'mono' },
      { data: 'master_number', className: 'mono' },
      { data: 'subawb_number', className: 'mono' },
      { data: 'manifest_number', className: 'mono' },
      { data: 'client_name' },
      { data: 'alt_refs' },
      { data: 'consigner' },
      { data: 'origin' },
      { data: 'destination' },
      { data: 'pieces', className: 'text-right mono' },
      { data: 'weight', className: 'text-right mono' },
      { data: 'depot_name' },
      { data: 'pieces_checkedin', className: 'text-center mono' },
      { data: 'pieces_secured', className: 'text-center mono' },
      { data: 'pieces_packed', className: 'text-center mono', orderable: false },
      { data: 'created_at', className: 'hidden' },
    ],
    order: [[18, 'desc']],
  });

  window.ajaxDataTableWithFilter({
    $element: $('#chooseForMergeOutboundShipments-table'),
    columns: [
      { data: 'id', orderable: false },
      { data: 'hazard_class' },
      { data: 'number', className: 'mono' },
      { data: 'hawb_number', className: 'mono' },
      { data: 'manifest_number', className: 'mono' },
      { data: 'client_name' },
      { data: 'origin' },
      { data: 'destination' },
      { data: 'pieces', className: 'text-right mono' },
      { data: 'weight', className: 'text-right mono' },
      { data: 'pieces_checkedin', className: 'text-center mono' },
      { data: 'pieces_secured', className: 'text-center mono' },
      { data: 'packages_count', className: 'packages-count hidden' },
    ],
    order: [[0, 'desc']],
  });

  window.markAndUnmarkRowItems('chooseForMergeOutboundShipments');
});
