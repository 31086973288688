/*
Show helpful messages when packing in the Web UI
 */

function calculateRemainingString(piecesRemaining) {
  if (piecesRemaining === -1) {
    return `<span class='red'>${I18n.t('views.packing.one_piece_too_much')}</span>`;
  } if (piecesRemaining < -1) {
    return `<span class='red'>${I18n.t('views.packing.n_pieces_too_much', { pieces: -piecesRemaining })}</span>`;
  } if (piecesRemaining === 1) {
    return `<span>${I18n.t('views.packing.one_piece_remaining')}</span>`;
  } if (piecesRemaining > 1) {
    return `<span>${I18n.t('views.packing.n_pieces_remaining', { pieces: piecesRemaining })}</span>`;
  }
  return "<i class='far fa-check-circle green' </i>";
}

function calculateRemainingKolli(inputField) {
  let remainingPieces;
  let sum = 0.0;
  const row = $(inputField).parents('tr');

  const values = $($(row).find('input')).map((idx, obj) => {
    let value = parseInt($(obj).val(), 10);
    if (isNaN(value)) { value = 0; }
    return value;
  });

  values.each((idx, val) => sum += (val));

  const pieces = $(row).data('pieces');

  if (pieces === undefined) {
    remainingPieces = 0;
  } else {
    remainingPieces = pieces - sum;
  }

  $($(row.find('td#remaining-pieces')))
    .html(calculateRemainingString(remainingPieces));
}

document.addEventListener('DOMContentLoaded', () => {
  $('#pack-table tr input[type=number]').on('change', function piecesCorrectnes() {
    calculateRemainingKolli($(this));
  });

  // Disables the submit button when there is a warning about to much "Kolli"
  // Check after every input change
  $('#pack-table tr input[type=number]').on('focusout', function() {
    $("#form_outbound_master_bup_packing :button").prop("disabled", $("#form_outbound_master_bup_packing span.red").length > 0);
  });

  $('input[data-select-all-on-focus="true"]').click(function selectThis() {
    $(this).select();
  });
});
