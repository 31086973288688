$(function() {
  const dropLayer = $("#drop-layer");

  // Only register drop layer events if layer is found
  if (dropLayer[0]) {
    let dropLayerEntered = false;

    const showDropLayer = function(e) {
      dropLayer.addClass('show');
      return dropLayer.removeClass('hide');
    };

    const hideDropLayer = function(e) {
      dropLayer.addClass('hide');
      return dropLayer.removeClass('show');
    };

    $(window).on("dragenter", function(e) {
      if (!dropLayerEntered) {
        showDropLayer(e);
        return dropLayerEntered = true;
      }
    });

    return $(dropLayer).on("dragleave", function(e) {
      if (dropLayerEntered) {
        hideDropLayer(e);
        return dropLayerEntered = false;
      }
    });
  }
});
