document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTable({
    $element: $('#stocktakingShipmentMissings-table'),
    columns: [
      { data: 'id', orderable: false },
      { data: 'shipment_type' },
      { data: 'shipment_client_name', orderable: false },
      { data: 'shipment_number', orderable: false, className: 'mono' },
      { data: 'shipment_hawb_number', orderable: false, className: 'mono' },
      { data: 'shipment_storage_periods_started_at', orderable: false },
      { data: 'shipment_pieces', orderable: false, className: 'mono' },
    ],
    order: [[0, 'desc']],
  });
});
