document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTable({
    $element: $('#impostCustomsEvents-table'),
    columns: [
      { data: 'number', orderable: false },
      { data: 'status', orderable: false },
      { data: 'created_at', orderable: false, className: 'text-center' }
    ],
    paging: false,
    order: [[0, 'desc']],
  });

  window.ajaxDataTable({
    $element: $('#customsEvents-table'),
    columns: [
      { data: 'number', orderable: false },
      { data: 'atb_number', orderable: false },
      { data: 'position', orderable: false, className: 'text-center' },
      { data: 'status_with_icon', orderable: false },
      { data: 'pieces_approved', orderable: false, className: 'text-center' },
      { data: 'valid_until', orderable: false, className: 'text-center' },
      { data: 'updated_at', orderable: false, className: 'text-center' }
    ],
    paging: false,
    order: [[0, 'desc']],
  });
});
