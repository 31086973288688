$(function() {
  $(document).ajaxStart(function() {
    $('html:not(.no-loading-indicator)').addClass('loading-pre');
  })

  $(document).ajaxComplete(function() {
    if ($.active > 1) { return; }
    $('html:not(.no-loading-indicator)').removeClass('loading-pre');
  });

  $(document).ajaxError(function() {
    $('html:not(.no-loading-indicator)').removeClass('loading');
  });
});
