document.addEventListener('DOMContentLoaded', () => {
  let columns = [
      { data: 'id', orderable: false },
      { data: 'hazard_class' },
      { data: 'customs_status', orderable: false },
      { data: 'number', className: 'mono' },
      { data: 'hawb_number', className: 'mono' },
      { data: 'master_number', className: 'mono' },
      { data: 'manifest_number', className: 'mono' },
      { data: 'client_name' },
      { data: 'origin', className: 'mono' },
      { data: 'destination', className: 'mono' },
      { data: 'pieces', className: 'mono text-right' },
      { data: 'weight', className: 'mono text-right' },
  ];
  window.ajaxDataTableWithFilter({
    $element: $('#chooseInboundPickupShipments-table'),
    columns: columns,
    createdRow: function( row, data, dataIndex, cells ) {
      const lockedRegex = /.*Gesperrt.*/;
      const releasedRegex = /.*Freigegeben.*/;
      const consignedRegex = /.*Abgegeben.*/;
      if (data.customs_status.match(lockedRegex)) {
        $(cells[2]).addClass("customs-locked");
      }
      if (data.customs_status.match(releasedRegex)) {
          $(cells[2]).addClass("customs-released");
      }
      if (data.customs_status.match(consignedRegex)) {
        $(cells[2]).addClass("customs-consigned");
      }
    },

    order: [[3, 'asc']],
  });

  window.markAndUnmarkRowItems('chooseInboundPickupShipments');
});
