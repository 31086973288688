document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#clients-table'),
    columns: [
      { data: 'status' },
      { data: 'name' },
      { data: 'acronym' },
      { data: 'tag' },
      { data: 'inbound_depots_name', orderable: false },
      { data: 'outbound_depots_name', orderable: false },
      { data: 'users_count', orderable: false },
      { data: 'slug' },
      { data: 'preferred_rac_number' },
    ],
    order: [[1, 'asc']],
  });
});
