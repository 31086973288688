document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#admin_slides-table'),
    columns: [
      { data: 'description' },
      { data: 'type', className: 'mono' },
      { data: 'query_params' },
      { data: 'dashboards_description' },
      { data: 'destroy', orderable: false },
    ],
    order: [[0, 'asc']],
  });
});
