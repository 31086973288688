/* eslint-env jquery */
import { handleDateInputs } from './src/jquery_utils/date_setter_buttons';
import { handleDepotEmail } from './src/jquery_utils/depot_email';

const handleDepotEmailIfMatchingForm = (form) => {
  const formsWithDepotEmailBehavior =
    '#reports-loose-check-reports-form, #reports-uld-build-reports-form';
  if (form.is(formsWithDepotEmailBehavior)) {
    handleDepotEmail(form);
  }
};

const handlePouchScope = (form) => {
  const formsWithPouch = '#pouch-reports-form';
  if (form.is(formsWithPouch)) {
    const scopeSelect = form.find('select[name=scope]');
    // eslint-disable-next-line func-names -- jquery this
    scopeSelect.change(function () {
      if ($(this).val() === 'depot') {
        form.find('.field.client').hide();
        form.find('.field.depot').show();
      } else if ($(this).val() === 'client') {
        form.find('.field.client').show();
        form.find('.field.depot').hide();
      } else {
        form.find('.field.client').hide();
        form.find('.field.depot').hide();
      }
    });
    scopeSelect.trigger('change');
  }
};

document.addEventListener('DOMContentLoaded', () => {
  const legacyReportSelector =
    '#export-tonnage-reports-form, #import-tonnage-reports-form, #pouch-reports-form, #reports-loose-check-reports-form, #reports-master-loads-reports-form,#reports-uld-build-reports-form, #spx-reports-form, #sniffer-usage-reports-form, #spx-reports-form, #tour-statistics-reports-form, #warehouse-manager-reports-form, #xray-sniffer-usage-reports-form, #xray-usage-reports-form';
  const form = $(legacyReportSelector);

  if (form.length) {
    handleDateInputs(form);
    handleDepotEmailIfMatchingForm(form);
    handlePouchScope(form);
  }
});
