document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#outboundArrivals-table'),
    columns: [
      { data: 'status' },
      { data: 'number', className: 'mono' },
      { data: 'origin' },
      { data: 'license_plate' },
      { data: 'driver_present_at' },
      { data: 'depot_name' },
      { data: 'trucker' },
      { data: 'hauler' },
      { data: 'manifest_number' },
      { data: 'shipments_count', className: 'text-right mono' },
      { data: 'pieces_checkedin', className: 'text-right mono', orderable: false },
      { data: 'shipments_weight', className: 'text-right mono' },
      { data: 'trucker_status', className: 'text-center' },
      { data: 'security_level', className: 'text-center' },
      { data: 'truck_sealed', className: 'text-center' },
    ],
    order: [[0, 'desc'], [4, 'desc']],
  });

  $(document).on('click', 'tr.link_arrival', function (e) {
    e.preventDefault();

    const $this = $(this);
    const $form = $this.closest('form');

    $form.attr('action', $this.data('href')).submit();
  });
});
