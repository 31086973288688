document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#outboundTourMasters-table'),
    columns: [
      { data: 'status' },
      { data: 'number', className: 'mono' },
      { data: 'lat' },
      { data: 'ground_handling_agent_name' },
      { data: 'airline_iata_code', className: 'mono' },
      { data: 'destination', className: 'mono' },
      { data: 'pieces', className: 'mono' },
      { data: 'weight', className: 'mono' },
      { data: 'bup_components_count', className: 'mono', orderable: false },
      { data: 'loose_bup_component' },
      { data: 'client_name' },
      { data: 'packs', orderable: false, className: 'text-center' },
      { data: 'id', orderable: false },
    ],
    order: [[2, 'asc']],
  });

  window.ajaxDataTableWithFilter({
    $element: $('#chooseOutboundTourMasters-table'),
    columns: [
      { data: 'id', orderable: false },
      { data: 'status', className: 'mono' },
      { data: 'pouch_captured_at', className: 'mono' },
      { data: 'number', className: 'mono' },
      { data: 'lat' },
      { data: 'ground_handling_agent_name' },
      { data: 'airline_iata_code', className: 'mono' },
      { data: 'destination', className: 'mono' },
      { data: 'pieces', className: 'mono' },
      { data: 'weight', className: 'mono' },
      { data: 'bup_components_count', orderable: false, className: 'mono' },
      { data: 'loose_bup_component' },
      { data: 'client_name' },
      { data: 'packs', orderable: false, className: 'text-center' },
    ],
    order: [[4, 'asc']],
    searchCols: [
      null,
      { search: 'pending' },
      { search: '!NULL' },
    ],
  });

  window.markAndUnmarkRowItems('chooseOutboundTourMasters');
});
