document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTable({
    $element: $('#inboundPickupLoads-table'),
    createdRow: function( row, data, dataIndex, cells ) {
      const lockedRegex = /.*Gesperrt.*/;
      const releasedRegex = /.*Freigegeben.*/;
      const consignedRegex = /.*Abgegeben.*/;
      if (data.customs_status.match(lockedRegex)) {
        $(cells[1]).addClass("customs-locked");
      }
      if (data.customs_status.match(releasedRegex)) {
        $(cells[1]).addClass("customs-released");
      }
      if (data.customs_status.match(consignedRegex)) {
        $(cells[1]).addClass("customs-consigned");
      }
    },
    footerOptions: {
      shipment_pieces: 'integer',
      shipment_weight: 'weight',
      pieces_checkedout: 'integer',
    },
    paging: false,
  });
});
