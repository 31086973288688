document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#clientUsageReports-table'),
    columns: [
      { data: 'status' },
      { data: 'title' },
      { data: 'start_date' },
      { data: 'end_date' },
      { data: 'amount' },
      { data: 'price' },
      { data: 'total' },
      { data: 'created_at' },
    ],
    order: [[7, 'desc']],
  });
});
